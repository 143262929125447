import { render, staticRenderFns } from "./SuggestionList.vue?vue&type=template&id=917255e4"
import script from "./SuggestionList.vue?vue&type=script&lang=ts"
export * from "./SuggestionList.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeSuggestionsSuggestionCard: require('/usr/src/nuxt-app/components/home/suggestions/SuggestionCard.vue').default})
