
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class SuggestionList extends Vue {
  public automobili: object = {
    size: "cardx2",
    title: "Autoveicoli e Cicli",
    description:
      "Una selezione di autoveicoli, moto, biciclette e altro della sezione mobiliare di beni in asta, clicca ed entra a scoprire di più",
    img: this.$config.cdnEndpoint + "home/sfondoauto.jpg",
    href: "/ricerca/mobili?filter%5Bgenre%5D%5B0%5D=MOBILI&filter%5Bcategory%5D%5B0%5D=AUTOVEICOLI E CICLI",
  };

  public immobili: object = {
    size: "cardx2",
    title: "Immobili Residenziali e Commerciali",
    description: "Case, appartamenti e altre tipologie di immobili in asta, la sezione perfetta per chi cerca affari",
    img: this.$config.cdnEndpoint + "home/sfondocasa.jpg",
    href: "/ricerca/immobili?filter%5Bgenre%5D%5B0%5D=IMMOBILI&filter%5Bcategory%5D%5B0%5D=IMMOBILE%20RESIDENZIALE&filter%5Bcategory%5D%5B1%5D=IMMOBILE%20COMMERCIALE",
  };

  public cucina: object = {
    size: "cardx1",
    title: "Arredamento e Elettrodomestici",
    description: "Mobili da casa e ufficio, elettrodomestici, tappeti e molto altro",
    img: this.$config.cdnEndpoint + "home/sfondocucina.jpg",
    href: "/ricerca/mobili?filter%5Bgenre%5D%5B0%5D=MOBILI&filter%5Bcategory%5D%5B0%5D=ARREDAMENTO%20-%20ELETTRODOMESTICI",
  };

  public aziende: object = {
    size: "cardx1",
    title: "Aziende e Cessione di Quote",
    description: "Beni in asta come quote di partecipazione societaria, cessioni o affitto di azienda",
    img: this.$config.cdnEndpoint + "home/sfondoazienda.jpg",
    href: "/ricerca/aziende?filter%5Bgenre%5D%5B0%5D=AZIENDE&filter%5Bcategory%5D%5B0%5D=QUOTA%20SOCIETARIA&filter%5Bcategory%5D%5B1%5D=CESSIONE-AFFITTO%20D%27AZIENDA",
  };

  public informatica: object = {
    size: "cardx1",
    title: "Informatica ed Elettronica",
    description: "Da Computer ad attrezzatura informatica in generale, passando da mobili e arredi per ufficio",
    img: this.$config.cdnEndpoint + "home/sfondomac.jpg",
    href: "/ricerca/mobili?filter%5Bgenre%5D%5B0%5D=MOBILI&filter%5Bcategory%5D%5B0%5D=INFORMATICA%20E%20ELETTRONICA",
  };

  public abbigliamento: object = {
    size: "cardx1",
    title: "Abbigliamento e Calzature",
    description: "Una sezione dedicata ad abbigliamento e calzature: prezzi convenienti per articoli da fallimenti ed esecuzioni",
    img: this.$config.cdnEndpoint + "home/sfondoabbigliamento.jpg",
    href: "/ricerca/mobili?filter%5Bgenre%5D%5B0%5D=MOBILI&filter%5Bcategory%5D%5B0%5D=ABBIGLIAMENTO%20E%20CALZATURE",
  };
}
